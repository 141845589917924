import { DASHBOARD } from "./appRoutes";
export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  USER_LOGIN: "/user_management/employee/login",

  //UPLOAD FILE

  UPLOAD_FILE: "/attachment_management/attachments/create_attachment",

  //DASHBOARD
  DASHBOARD: "/dashboard_management/dashboards/main",
  ADMIN_USER_GRAPH: "/dashboard_management/dashboards/users_graph",
  DASHBOARD_TAT: "/dashboard_management/dashboards/average_tat",
  SUPER_ADMIN_OUTSTANDING_GRAPH:
    "/dashboard_management/dashboards/outstanding_graph",
  SUPER_ADMIN_DISBURSED_GRAPH:
    "/dashboard_management/dashboards/disbursed_graph",
  HO_APPLICATION_GRAPH: "/dashboard_management/dashboards/application_graph",
  HO_APPROVAL_GRAPH: "/dashboard_management/dashboards/approval_graph",

  // META
  GET_ROLES: "meta/roles",
  GET_REGIONS: "meta/regions",
  GET_INDIAN_STATES: "meta/indian_states",
  GET_CITIES: "meta/states/:stateId/cities",
  GET_ORGANIZATIONS: "meta/organisations",
  GET_LOAN_TYPES: "meta/loan_types",
  GET_LOAN_TYPES_LIST: "meta/loan_types/list_details",
  GET_LOAN_STATUS: "meta/loan_statuses",
  GET_INSTALLMENT_STATUS: "meta/instalment_statuses",
  GET_STATUSES: "meta/statuses",
  GET_HYPOTHECATORS: "loan_management/hypothecators",
  GET_TENURES: "meta/tenures",
  GET_RECEIPT: "meta/receipt_pdf",

  // BRANCHES
  BRANCHES: "/config/branches",
  BRANCH: "/config/branches/:id",
  BRANCH_TARGET: "/config/targetable_type/Branch/targetable_id/:id/target",

  // CENTERS
  CENTERS: "/config/centers",

  // PASSWORD
  FOGOT_PASSWORD: "/user_management/employee/forgot_password",
  VERIFY_OTP: "/user_management/employee/verify_otp",
  RESET_PASSWORD: "/user_management/employee/reset_password",
  RESET_NEW_PASSWORD: "/user_management/employee/change_password",
  SEND_OTP: "/user_management/employee/send_otp",
  VERIFY_EMAIL_OTP: "/user_management/employee/verify_otp_email",
  // EMPLOYEES
  EMPLOYEES: "/user_management/employees",
  EMPLOYEE_DETAIL: "/user_management/employees/:employeeId",
  EMPLOYEE_STATUS: "/user_management/employee/change_active_status",
  ASSIGN_USER: "/user_management/employee/assign_user",
  EMPLOYEE_OFFLINE_LOAN: "/user_management/employee/offline_journey_access",

  // LOANS
  LOANS: "/loan_management/loans",
  LOAN_DOCUMENTS_UPLOAD: "/loan_management/loans/:loanId/customer/create_bulk_documents",
  LOAN_TYPE: "/loan_management/loan_types",
  LOAN_TYPE_DETAILS:
    "/loan_management/loan_types/:loanTypeId/loan_type_details",
  LOAN_TYPE_CONDITON:
    "/loan_management/loan_types/:loanTypeId/loan_type_conditions",
  UPDATE_LOAN_TYPE_CONDITON:
    "/loan_management/loan_types/:loanTypeId/loan_type_conditions/:id",
  UPDATE_CT_VERIFICATION_STATUS: "/loan_management/loans/update_status",
  LOAN_TYPE_CONFIGURATION:
    "/loan_management/loan_types/:loanTypeId/loan_type_configuration",
  LOAN_EMI_DETAILS: "/loan_management/loans/:loanId/loan_emi_detail",
  LOAN_DETAIL: "/loan_management/loans/:loanId",
  APPROVE_LOAN: "/loan_management/loans/:loanId/approve",
  REJECT_LOAN: "/loan_management/loans/:loanId/reject",
  LOAN_SUMMARY: "/loan_management/loans/:loanId/summary",
  LOAN_REPORT: "/loan_management/loans/generate_report",
  GENERATE_REPORT: "/report_management/reports/generate_report",
  GENERATE_LOAN_REPORT_CARD: "/loan_management/loans/:loanId/loan_card",
  OFFLINE_REPORT: "/loan_management/offline_loans",
  DISBURSE_AMOUNT: "/loan_management/loans/loans_to_disburse",
  INITIATE_DISBURSE_STATUS: "/loan_management/loans/initiate_disbursement",
  COMPLETE_DISBURSE_STATUS: "/loan_management/loans/complete_disbursement",
  REVERT_DISBURSE_STATUS: "/loan_management/loans/revert_disbursement",
  LOANS_REPAYMENT_SCHEDULE: "/due_management/loans/:loanId/repayments",
  LOANS_EMI_REPAYMENT_SCHEDULE:
    "/due_management/loans/:loanId/repayment_schedule",
  LOANS_TIMELINE: "/loan_management/loans/:loanId/timeline",
  LOANS_WALLET: "/loan_management/loans/:loanId/wallet",
  PRE_CLOSE: "/loan_management/loans/:loanId/pre_close",
  MORATORIUM: "/loan_management/loans/:loanId/moratorium",
  WRITE_OFF: "/loan_management/loans/:loanId/write_off",
  LOAN_INSTALLMENT: "/loan_management/loans/:loanId/instalments",
  TRANSFER_LOAN: "/loan_management/loans/transfer_loans",
  LOAN_DEDUCTION: "meta/deductions",
  INDIVIDUAL_LOAN_DEDUCTION: "meta/deductions/:id",
  UPDATE_LOAN_TYPE_STATUS: "meta/loan_types/:id",
  UPDATE_NAME_MATCH_SCORE:
    "/loan_management/loans/:loanId/customer/bank_score_approve",
  TENURE_CHANGE_SEND_OTP: "/loan_management/loans/send_otp",
  TENURE_CHANGE_VERIFY_OTP: "/loan_management/loans/verify_otp",
  UPDATE_LOANS_WITH_HYPOTHECATOR: "/loan_management/loans/initiate_book_debt",
  LOANS_REPAYMENT_TENURE_MONTHS: '/loan_management/loans/:loanId/applicable_tenures',
  NACH_IMPORT: "/due_management/loans/nach_import",
  NACH_TEMPLATE: "/report_management/reports/download_template_nach",
  LOAN_PAY_EMI: "/due_management/dues/pay_emi",
  CONFIRM_DUE_PAYMENT: "/due_management/dues/confirm_payment",
  LOAN_AGREEMENT_UPLOAD: "/loan_management/loans/loan_agreement_upload",
  TOTAL_LOANS_AMOUNT: "/loan_management/loans/loan_total_amount",

  //REPAYMENTS
  REPAYMENTS: "/due_management/repayments",
  REPAYMENTS_DASHBOARD: "/dashboard_management/dashboards",
  REPAYMENTS_DASHBOARD_GRAPH:
    "/dashboard_management/dashboards/repayments_graph",
  DEPOSITS: "/due_management/deposits",
  CASH_ON_HAND: "/due_management/cash_on_hand",
  PAYMENTS: "/due_management/payments",

  REGENERATE_REPAYMENT:
    "/due_management/repayments/:loanId/regenerate_schedule",
  WAIVE_REQUEST: "/due_management/waive_requests",
  WAIVE_REQUEST_APPROVE: "/due_management/waive/approve/:id",
  WAIVE_REQUEST_REJECT: "/due_management/waive/reject/:id",

  // CUSTOMERS
  CUSTOMERS: "/user_management/customers",
  CREATED_CUSTOMERS: "/user_management/employee/:employeeId/my_customers",
  CUSTOMER_DETAIL: "/user_management/customers/:customerId",
  CUSTOMER_DETAIL_CODE: "/user_management/customer/summary",
  CUSTOMER_UPDATE: "/loan_management/loans/:customerId/customer",
  CUSTOMER_EXISTING_LOAN: "/loan_management/customers/existing_loans",

  //USER ACCESS
  USER_ACCESS: "/meta/role/user_accesses",
  USER_ACCESS_UPDATE: "/meta/role/:id/user_access",

  //COMMENTS
  ADD_COMMENT: "/comment_management/loans/:loanId/comments",

  //BLACKLIST
  BLACKLIST: "/blacklist_management/blacklists",

  //REPAYMENT REGULARISATION
  GET_REPAYMENT_REGULARISATIONS: "/due_management/regularisations",
  UPDATE_REGULARISATION_STATUS: "/due_management/regularise",

  CRIF_REPORT: "/loan_management/credit_info",

  //MOD 
  RETURN_MOD_DOCUMENT: "/loan_management/loans/:loanId/return_mod_document",
  ACKNOWLEDGE_DOCUMENT: "/loan_management/loans/:loanId/acknowlege_mod_loan",
  CREATE_CUSTOMER_DOCUMENT: "/loan_management/loans/:loanId/customer/documents",

  //CHANGE PREFERRED CHANNEL IN DISBURSEMENT
  CHANGE_PREFERRED_CHANNEL_DISBURSEMENT: "/loan_management/loans/change_preferred_channel",

  //CLAIMS
  LISTING_CLAIMS: "/loan_management/claims",
  PROCESS_GROUP_CLAIM: "/loan_management/claim/process_claims",
  PROCESS_INDIVIDUAL_CLAIM: "/loan_management/claims/:claimId/process_claim",
  GET_INDIVIDUAL_CLAIM: "/loan_management/claim/summary",
  PROCESS_MARKED_CLAIM: "/loan_management/claims/:claimId/complete_claim",
  CLAIM_DOCUMENT_UPLOAD: "/loan_management/claims/:claimId",
};
