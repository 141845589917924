import React, { useEffect } from 'react'
import { MetaService } from '../../../services/Meta/meta.service'
import "./paymentReceipt.scss"
const PaymentReceipt = () => {

    const { getReceipt, paymentReceipt } = MetaService()

    useEffect(() => {
        getReceipt({
            id: 156131
        })
    }, [])

    return (
        <iframe
            className="pdf-container__iframe"
            src={paymentReceipt?.receiptLink}
            title="pdf-viewer"
        />
    )
}

export default PaymentReceipt